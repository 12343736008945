import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import QuoteForm from '../../components/Form/QuoteForm';

const Contact = () => {
  return (
    <>
      <PageHelmet pageTitle='Contact Page' />

      <HomeOneHeader />
      <CommonPageHeader title='Get a free quote | Contact' subtitle='Contact' />
      <QuoteForm />
      <Footer />
    </>
  );
};

export default Contact;
