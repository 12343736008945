import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';

function QuoteForm() {
  const recaptchaRef = useRef(); // Create a ref for the reCAPTCHA
  const form = useRef(); // Create a ref for the form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm();

  const sendEmail = async (data, e) => {
    e.preventDefault();

    // Execute the reCAPTCHA when the form is submitted
    const token = await recaptchaRef.current.executeAsync();
    if (!token) {
      setError('recaptcha', {
        type: 'manual',
        message: 'reCAPTCHA verification failed. Please try again.'
      });
      return;
    }

    // Use EmailJS to send the form data
    emailjs
      .sendForm(
        'service_3485272345', // Replace with your EmailJS service ID
        'template_muwqmw7', // Replace with your EmailJS template ID
        form.current,
        'ligiQRZ0xU_eTapHk' // Replace with your EmailJS public key
      )
      .then(
        () => {
          alert('Your quote request has been submitted!');
          reset(); // Reset the form after successful submission
        },
        (error) => {
          console.log('FAILED...', error.text);
          setError('submit', {
            type: 'manual',
            message:
              'There was an error submitting the form. Please try again later.'
          });
        }
      );
  };

  return (
    <div className='d-flex justify-content-center my-5'>
      <div className='form-container p-4 rounded'>
        <h2 className='form-title text-center mb-4'>Get a free quote</h2>
        <form
          ref={form}
          onSubmit={handleSubmit(sendEmail)}
          className='quote-form'
        >
          {/* Your form fields go here */}
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>Your Name*</label>
              <input
                type='text'
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                {...register('name', { required: 'Name is required' })}
              />
              {errors.name && (
                <p className='form-error'>{errors.name.message}</p>
              )}
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>Your Email Address*</label>
              <input
                type='email'
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Enter a valid email address'
                  }
                })}
              />
              {errors.email && (
                <p className='form-error'>{errors.email.message}</p>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>Your Phone Number*</label>
              <input
                type='tel'
                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                {...register('phone', { required: 'Phone number is required' })}
              />
              {errors.phone && (
                <p className='form-error'>{errors.phone.message}</p>
              )}
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>Type of property</label>
              <select
                className='form-select'
                {...register('propertyType')}
                defaultValue='New Build'
              >
                <option value='New Build'>New Build/Home</option>
                <option value='Commercial'>Commercial</option>
                <option value='Rental'>Rental</option>
              </select>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>Address Line 1*</label>
              <input
                type='text'
                className={`form-control ${
                  errors.addressLine1 ? 'is-invalid' : ''
                }`}
                placeholder='Address Line 1'
                {...register('addressLine1', {
                  required: 'Address Line 1 is required'
                })}
              />
              {errors.addressLine1 && (
                <p className='form-error'>{errors.addressLine1.message}</p>
              )}
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>Address Line 2</label>
              <input
                type='text'
                className='form-control'
                placeholder='Address Line 2'
                {...register('addressLine2')}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-4 mb-3'>
              <label className='form-label'>Town/City*</label>
              <input
                type='text'
                className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                {...register('city', { required: 'City is required' })}
              />
              {errors.city && (
                <p className='form-error'>{errors.city.message}</p>
              )}
            </div>

            <div className='col-md-4 mb-3'>
              <label className='form-label'>Post Code*</label>
              <input
                type='text'
                className={`form-control ${
                  errors.postCode ? 'is-invalid' : ''
                }`}
                {...register('postCode', { required: 'Post Code is required' })}
              />
              {errors.postCode && (
                <p className='form-error'>{errors.postCode.message}</p>
              )}
            </div>

            <div className='col-md-4 mb-3'>
              <label className='form-label'>Bedrooms*</label>
              <input
                type='number'
                className={`form-control ${
                  errors.bedrooms ? 'is-invalid' : ''
                }`}
                {...register('bedrooms', {
                  required: 'Number of bedrooms is required',
                  min: 1
                })}
                min='1'
              />
              {errors.bedrooms && (
                <p className='form-error'>{errors.bedrooms.message}</p>
              )}
            </div>
          </div>

          <div className='mb-3'>
            <label className='form-label'>Builder / Developer</label>
            <input
              type='text'
              className='form-control'
              {...register('builderDeveloper')}
            />
          </div>

          <div className='mb-3'>
            <label className='form-label'>Move In / Completion Date</label>
            <input
              type='date'
              className='form-control'
              {...register('moveInDate')}
            />
          </div>

          <div className='mb-3'>
            <label className='form-label'>Rental or Purchase*</label>
            <div>
              <div className='form-check form-check-inline'>
                <input
                  type='radio'
                  className={`form-check-input ${
                    errors.purchaseOrRental ? 'is-invalid' : ''
                  }`}
                  value='Purchase'
                  {...register('purchaseOrRental', {
                    required: 'This field is required'
                  })}
                />
                <label className='form-check-label'>Purchase</label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  type='radio'
                  className={`form-check-input ${
                    errors.purchaseOrRental ? 'is-invalid' : ''
                  }`}
                  value='Rental'
                  {...register('purchaseOrRental', {
                    required: 'This field is required'
                  })}
                />
                <label className='form-check-label'>Rental</label>
              </div>
            </div>
            {errors.purchaseOrRental && (
              <p className='form-error'>{errors.purchaseOrRental.message}</p>
            )}
          </div>

          <div className='mb-3'>
            <label className='form-label'>
              Anything else we may need to know?
            </label>
            <textarea
              className='form-control'
              {...register('additionalInfo')}
            />
          </div>

          {/* reCAPTCHA v3 - Invisible */}
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey='6LfkZSoqAAAAAFVsn-woJ32qMHl9aWZE2fQWCPYq'
            size='invisible'
            onChange={() => {}}
          />

          {errors.recaptcha && (
            <p className='form-error'>{errors.recaptcha.message}</p>
          )}

          {errors.submit && (
            <p className='form-error'>{errors.submit.message}</p>
          )}

          <button type='submit' className='z-btn w-100'>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default QuoteForm;
