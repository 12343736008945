import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer__area pt-50">
          <div className="footer__top pb-10">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 text-center text-md-start">
                  <div className="logo d-flex align-items-center justify-content-center justify-content-md-start">
                    <NavLink to="/">
                      <img
                        className="logo-main"
                        src="assets/img/logo/without-background.svg"
                        alt="logo"
                        style={{ marginBottom: "20px" }}
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 text-center text-md-start">
                  <div className="footer__widget mb-25">
                    <div className="footer__widget-title">
                      <h4>Company</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__links">
                        <ul>
                          <li>
                            <Link to="/about">About</Link>
                          </li>
                          <li>
                            <Link to="/snagging-services">Snagging</Link>
                          </li>
                          <li>
                            <Link to="/construction-services">
                              Construction Services
                            </Link>
                          </li>
                          <li>
                            <Link to="/reviews">Reviews</Link>
                          </li>
                          <li>
                            <Link to="/Contact">Contact</Link>
                          </li>
                          <li>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="/terms-and-conditions">
                              Terms & Conditions
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 col-sm-6 text-center text-md-start">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title">
                      <h4>Information</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__info">
                        <ul>
                          <li>
                            <div
                              className="icon"
                              style={{
                                alignContent: "center",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <i>
                                <FaEnvelope />
                              </i>
                            </div>
                            <div className="text">
                              <span>
                                <a href="mailto:besurebuild@outlook.com">
                                  besurebuild@outlook.com
                                </a>
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i>
                                <FaPhone />
                              </i>
                            </div>
                            <div className="text">
                              <span>
                                <a href="tel:(+44)-7777-100-221">07777100221</a>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__copyright">
            <div className="container">
              <div className="row align-items-center text-center text-md-start">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="footer__copyright-text">
                    <p>
                      ©{new Date().getFullYear()} <b>BeSure Build </b>
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="footer__social theme-social text-center text-md-end">
                    Website created by{" "}
                    <a
                      target="_blank"
                      href="https://www.lixu.digital"
                      rel="noreferrer"
                    >
                      <b>Lixu Digital</b>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
