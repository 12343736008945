import { CgArrowLongRight } from "react-icons/cg";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

const HomeAbout = () => {
  return (
    <>
      <section className="about__area pb-100 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              <div className="about__thumb m-img">
                <img
                  style={{ borderRadius: "20px" }}
                  src="assets/img/about/about-3.webp"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="mb-25">
                  <span>Who we are</span>
                  <h2>Expert snagging/quality control solutions</h2>
                </div>
                <p>
                  BeSureBuild offers thorough snagging surveys throughout the
                  North West and across the UK, ensuring your investment is
                  safeguarded and delivering the quality home you deserve.
                </p>
                <div className="about__list">
                  <ul>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        New home snagging
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Snagging reports
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Final inspections
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Training and support
                      </span>
                    </li>
                  </ul>
                </div>
                <Link to="/snagging-services" className="z-btn ">
                  Find out more
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
