import React from 'react';
import { FaDownload } from 'react-icons/fa';

const CommonCtaArea = () => {
   const handleDownload = () => {
    // Access the PDF from the public folder
    const pdfPath = process.env.PUBLIC_URL + '/BeSureBuild-Snag-Checklist.pdf';

    // Create a temporary link element for downloading
    const link = document.createElement('a');
    link.href = pdfPath;
    link.setAttribute('download', 'BeSureBuild-Snag-Checklist.pdf'); // Set a custom filename if desired
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Remove the link element after download
    document.body.removeChild(link);
  };

  return (
    <>
      <section
        className='cta__area pt-180 pb-155'
        style={{
          background: `url(assets/img/slider/slider-1.webp)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <div className='container p-relative'>
          <div className='row'>
            <div className='col-xl-10 offset-xl-1'>
              <div className='cta__content text-center'>
                <h1>Get your free checklist</h1>
                <div className='cta__btn' onClick={handleDownload}>
                  <div
                    className='z-btn z-btn-white mb-30 hover-icon'
                    onClick={handleDownload}
                    style={{ cursor: 'pointer'}}
                  >
                    <FaDownload
                      className='download-icon'
                      style={{
                        marginRight: '10px'
                      }}
                    />
                    Click here
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonCtaArea;
