import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Collapsible from "react-collapsible";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Sidebar = ({ show, handleClose }) => {
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            <div className="logo">
              <a href="index.html">
                <img
                  src="assets/img/logo/without-background.svg"
                  alt="logo"
                  style={{
                    height: "70px",
                    width: "70px",
                  }}
                />
              </a>
            </div>
          </Offcanvas.Header>

          <Offcanvas.Body>
            <section>
              <div className="p-0">
                <div className="sidebar__content">
                  {/* Sidebar Navigation */}
                  <div className="side_navBar">
                    <li className="pt-0">
                      <NavLink to="/home">Home</NavLink>
                    </li>

                    <div className="about iconAdd">
                      <NavLink to="/about">About</NavLink>
                    </div>
                    <div className="about iconAdd">
                      <NavLink to="/reviews">Reviews</NavLink>
                    </div>

                    <Collapsible
                      trigger={"Services"}
                      triggerTagName="div"
                      triggerOpenedClassName="icon_close"
                      triggerClassName="iconAdd"
                      open={false}
                    >
                      <ul className="sidebar_sub_menu submenu text-white">
                        <li>
                          <NavLink to="/snagging-services">Snagging</NavLink>
                        </li>
                        <li>
                          <NavLink to="/construction-services">
                            Construction
                          </NavLink>
                        </li>
                      </ul>
                    </Collapsible>

                    <div className="about iconAdd border-0">
                      <NavLink to="/contact">Contact </NavLink>
                    </div>
                  </div>

                  {/* Contact Information */}
                  <div className="sidebar__info mt-135">
                    <Link
                      to="/contact"
                      className="z-btn z-btn-white justify-content-center"
                    >
                      Free quote
                    </Link>
                  </div>

                  {/* Contact Details */}
                  <div className="sidebar__contact mt-4">
                    <ul>
                      <li>
                        <div className="icon mr-10">
                          <FaEnvelope />
                        </div>
                        <div className="text">
                          <span>
                            <a href="mailto:besurebuild@outlook.com">
                              besurebuild@outlook.com
                            </a>
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="icon mr-10">
                          <FaPhoneAlt />
                        </div>
                        <div className="text">
                          <span>
                            <a href="tel:(+44)-7777-100-221">07777100221</a>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
