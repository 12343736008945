import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';

const PrivacyPolicy = () => {
  return (
    <>
      <PageHelmet pageTitle='Privacy policy' />

      <HomeOneHeader />
      <CommonPageHeader title='Privacy Policy' subtitle='Privacy Policy' />

      <div className='container mt-20'>
        <h1 className='text-center mb-4'>Privacy Policy</h1>
        <p>
          <strong>Last Updated:</strong> 20-Aug-2024
        </p>
        <p>
          <strong>Effective Date:</strong> 20-Aug-2024
        </p>

        <p>
          This Privacy Policy outlines the policies of{' '}
          <strong>BeSure Build</strong>, United Kingdom of Great Britain and
          Northern Ireland, regarding the collection, use, and disclosure of
          information we collect when you visit our website (the “Service”). By
          using or accessing the Service, you agree to the collection, use, and
          disclosure of your information as described in this Privacy Policy. If
          you do not agree, please refrain from using or accessing the Service.
        </p>

        <p>
          We reserve the right to modify this Privacy Policy at any time without
          prior notice. We will post the updated Privacy Policy on our Service,
          which will become effective 180 days from the date of posting.
          Continued use of the Service following the posting of changes
          indicates your acceptance of those changes. Therefore, we recommend
          regularly reviewing this page.
        </p>

        <h3>Information We Collect:</h3>
        <ul>
          <li>Name</li>
          <li>Email Address</li>
          <li>Mobile Number</li>
          <li>Address</li>
        </ul>

        <h3>How We Use Your Information:</h3>
        <p>We use the information collected for the following purpose:</p>
        <ul>
          <li>Managing customer orders</li>
        </ul>
        <p>
          If we intend to use your information for any other purpose, we will
          first request your consent. We will only use your information for the
          purposes for which you have given consent unless required otherwise by
          law.
        </p>

        <h3>Retention of Your Information:</h3>
        <p>
          We retain your personal information for 90 days to 2 years after user
          accounts remain inactive or for as long as necessary to fulfill the
          purposes for which it was collected, as described in this Privacy
          Policy. We may retain certain information longer to comply with legal
          obligations, such as record-keeping or reporting, or for other
          legitimate purposes like fraud prevention. Anonymous and aggregate
          data, which cannot identify you, may be retained indefinitely.
        </p>

        <h3>Your Rights:</h3>
        <p>
          Depending on the applicable laws, you may have rights regarding your
          personal data, including the right to access, rectify, or erase it;
          restrict or object to processing; request data portability; withdraw
          consent; or lodge a complaint with a statutory authority. To exercise
          these rights, please contact us at{' '}
          <a href='mailto:besurebuild@outlook.com'>besurebuild@outlook.com</a>.
          We will respond according to applicable law.
        </p>
        <p>
          Please note that if you do not provide the necessary personal
          information or withdraw consent for required processing, you may be
          unable to use certain services for which your information was
          requested.
        </p>

        <h3>Security:</h3>
        <p>
          We take the security of your information seriously and implement
          reasonable measures to protect it from loss, misuse, or unauthorised
          alteration. However, no data transmission over the internet can be
          completely secure, and we cannot guarantee absolute security. You
          transmit information to us at your own risk.
        </p>

        <h3>Grievance / Data Protection Officer:</h3>
        <p>
          If you have questions or concerns about how your information is
          processed, you can reach our Data Protection Officer at BeSure Build,
          email:{' '}
          <a href='mailto:besurebuild@outlook.com'>besurebuild@outlook.com</a>.
          We will address your concerns in compliance with applicable laws.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
