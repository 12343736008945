import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import Snagging from "./pages/SnaggingDetails/Snagging";
import ConstructionServices from "./pages/ConstructionDetails/Construction";
import PrivacyPolicy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/terms-and-conditions";
import ReviewsPage from "./pages/Reviews/ReviewsPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/snagging-services" element={<Snagging />} />
          <Route
            path="/construction-services"
            element={<ConstructionServices />}
          />
          <Route path="/reviews" element={<ReviewsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />

          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
