import React from 'react';

const ConstructionServicesDetailsHeader = () => {
  return (
    <>
      <section
        className='page__title page__title-2 p-relative d-flex align-items-center fix'
        style={{
          background: `url(assets/img/page-title/page-title-1.webp)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <div className='slider__shape'>
          <img
            className='shape dotted-square'
            src='assets/img/icon/slider/dotted-square.svg'
            alt='dotted-square'
          />
          <img
            className='shape solid-square'
            src='assets/img/icon/slider/solid-square.svg'
            alt='solid-square'
          />
          <img
            className='shape circle-2'
            src='assets/img/icon/slider/home.svg'
            alt='circle'
          />
        </div>
        <div className='container p-relative'>
          <div className='row'>
            <div className='col-xl-7'>
              <div className='page__title-content mt-100'>
                <span>North West</span>
                <h3> Construction Services</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConstructionServicesDetailsHeader;
