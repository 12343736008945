import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import HomeAbout from "../Home/HomeAbout/HomeAbout";
// import HomeTwoFaq from '../HomeTwo/HomeTwoFaq/HomeTwoFaq';

const About = () => {
  return (
    <>
      <PageHelmet pageTitle="About" />

      <HomeOneHeader />
      <CommonPageHeader title="About" subtitle="About" />
      {/* <HomeTwoFaq /> */}
      <HomeAbout />
      <Footer />
    </>
  );
};

export default About;
