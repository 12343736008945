import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";

const Terms = () => {
  return (
    <>
      <PageHelmet pageTitle="Terms and Conditions" />

      <HomeOneHeader />
      <CommonPageHeader title="Terms & Conditions" subtitle="T&C" />

      <div className="container mt-20">
        <h1 className="text-center mb-4">Terms & Conditions</h1>
        <p>
          <strong>Last Updated:</strong> 20-Aug-2024
        </p>
        <p>
          <strong>Effective Date:</strong> 20-Aug-2024
        </p>

        <h3>Payment</h3>
        <p>
          Each booking will require a £50 deposit to ensure your slot which is
          non-fundable however gets taken away from the the total outstanding
          balance upon completion.
        </p>

        <p>Payment is due in full on completion. </p>
        <p>
          If a BeSure Build inspector is unable to gain entry, is refused entry,
          or is asked to leave the premises at any point during the inspection,
          an appropriate inspection fee will be charged to cover the inspector’s
          time and travel expenses. It is the client’s responsibility to confirm
          access arrangements with all relevant parties in advance.
        </p>

        <h3>Reschedule Policy</h3>
        <p>
          Clients may reschedule the inspection at any time for any reason, with
          no reschedule fee, provided the arrangement is confirmed by speaking
          directly with a BeSure Build representative by phone no later than 5pm
          on the day prior to the inspection.
        </p>

        <h3>The Inspection</h3>
        <p>
          The inspection is a visual survey of the property, assessing the
          quality of workmanship against applicable standards, and can only be
          conducted in areas where the inspector has open access. It is the
          client’s responsibility to ensure access is available to all areas of
          the property at the scheduled date and time.
        </p>

        <h3>Force Majeure</h3>
        <p>
          Your qualified BeSure Build inspector will aim to arrive at the
          property at the agreed-upon time. However, they cannot be held liable
          for delays due to traffic or other unforeseen circumstances outside
          their control.
        </p>
        <h3>Disclaimer</h3>
        <p>
          The services provided by BeSure Build are intended to offer a
          comprehensive assessment of new home quality through snagging
          inspections. While we strive to identify defects and ensure the
          highest level of accuracy in our reports, our findings are based on
          visual inspections and accessible areas of the property at the time of
          assessment. BeSure Build does not guarantee the discovery of all
          possible defects or latent issues that may arise after our inspection.
          Our reports should not be considered a substitute for legal advice or
          a professional structural survey. BeSure Build is not liable for any
          damage, loss, or costs incurred as a result of defects or issues that
          were not identified during our inspection or that develop
          post-inspection. Clients are advised to seek independent professional
          guidance for any concerns that fall outside the scope of our services.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default Terms;
