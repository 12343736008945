import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import Reviews from "./GoogleReviews";

const ReviewsPage = () => {
  return (
    <>
      <PageHelmet pageTitle="Reviews" />

      <HomeOneHeader />
      <CommonPageHeader
        title="Reviews"
        subtitle="What do our customers say about us?"
      />

      <div className="container mt-20">
        <Reviews />
      </div>

      <Footer />
    </>
  );
};

export default ReviewsPage;
