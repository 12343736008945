import { useState } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaSearch,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";

const HomeOneHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <header>
        <div className="header__area p-relative header__transparent">
          <div className="header__top">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 d-flex justify-content-center">
                  <div className="header__social">
                    <ul className="d-flex justify-content-center">
                      <li>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/profile.php?id=61563837812139"
                          rel="noreferrer"
                        >
                          <i>
                            <FaFacebookF />
                          </i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/besure-build-63a92031b/"
                          rel="noreferrer"
                        >
                          <i>
                            <FaLinkedinIn />
                          </i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/besurebuild"
                          rel="noreferrer"
                        >
                          <i>
                            <FaInstagram />
                          </i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="header__sticky" className={"header__bottom"}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="logo">
                    <NavLink to="/">
                      <img
                        className="logo-main"
                        src="assets/img/logo/without-background.svg"
                        alt="logo"
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                  <div className="header__bottom-right d-flex justify-content-end align-items-center">
                    <div className="main-menu menu_wrapper_one">
                      <nav id="mobile-menu">
                        <ul>
                          <li>
                            <NavLink to="/home">Home</NavLink>
                          </li>
                          <li>
                            <NavLink to="/about">About</NavLink>
                          </li>
                          <li>
                            <span
                              style={{
                                color: "#ffffff",
                                fontWeight: "700",
                                cursor: "pointer",
                                padding: "20px 0",
                                paddingBottom: "25px",
                                display: "inline-block",
                                fontSize: "15px",
                              }}
                            >
                              Services
                            </span>
                            <ul className="submenu">
                              <li>
                                <NavLink to="/snagging-services">
                                  Snagging
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/construction-services">
                                  Construction Services
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                          {/* New Reviews Link */}
                          <li>
                            <NavLink to="/reviews">Reviews</NavLink>
                          </li>
                          <li>
                            <NavLink to="/contact">Contact</NavLink>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="header__btn d-none d-sm-block d-lg-none d-xl-block ml-50">
                      <Link to="/contact" className="z-btn z-btn-white">
                        Free Quote
                      </Link>
                    </div>
                    <div
                      onClick={handleShow}
                      className="sidebar__menu d-lg-none"
                    >
                      <div className="sidebar-toggle-btn" id="sidebar-toggle">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header__search-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <form action="#">
                    <input type="text" placeholder="Your Keywords" />
                    <button type="button">
                      <i>
                        {" "}
                        <FaSearch />{" "}
                      </i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="body-overlay-2"></div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeOneHeader;
