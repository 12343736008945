import React from "react";
import { FaStar } from "react-icons/fa";

// Placeholder avatar
const placeholderAvatar = "https://via.placeholder.com/150?text=No+Image"; // Replace with a grayed-out avatar if needed

const reviews = {
  average: 5,
  totalCount: 7,
  counts: [
    { rating: 5, count: 7 },
    { rating: 4, count: 0 },
    { rating: 3, count: 0 },
    { rating: 2, count: 0 },
    { rating: 1, count: 0 },
  ],
  featured: [
    {
      id: 1,
      rating: 5,
      content: `
        <p>Wanted some peace of mind when I bought my new build. Alan was very easy to deal with and did a great job finding all the defects in my home, most of them I would have never noticed myself. Would recommend to anyone!</p>
      `,
      author: "Emma Wotherspoon",
      avatarSrc: placeholderAvatar,
    },
    {
      id: 2,
      rating: 5,
      content: `
        <p>We recently used Besure when moving into our new home and were thoroughly impressed. They found a list of issues we would never have noticed ourselves. The service was professional and meticulous, giving us peace of mind about our new home. Highly recommend!</p>
      `,
      author: "David",
      avatarSrc: placeholderAvatar,
    },
    {
      id: 3,
      rating: 5,
      content: `
        <p>After purchasing our home, we decided to hire a professional snagger. A friend recommended BeSure, so we chose them. They were incredibly easy to work with and identified far more issues than we had anticipated. Excellent service—I would highly recommend them!</p>
      `,
      author: "Alexandra Schofield",
      avatarSrc: placeholderAvatar,
    },
    {
      id: 4,
      rating: 5,
      content: `
        <p>After struggling to get my deposit back last time, I was recommended to reach out to this guy. He came over and provided a detailed report of all the issues in the flat. I forwarded it to my landlord, who addressed some of the problems.</p>
      `,
      author: "Carl",
      avatarSrc: placeholderAvatar,
    },
    {
      id: 5,
      rating: 5,
      content: `
        <p>We had Besure round to snag our new build. Being in the trades, I thought I wasn't going to be too bad at spotting the snags, but I was wrong. Alan came and found a lot more than I did, most of them minor which I was never going to pick up, but he did. Highly recommended!</p>
      `,
      author: "Rob Jolly",
      avatarSrc: placeholderAvatar,
    },
    {
      id: 6,
      rating: 5,
      content: `
        <p>So glad we got Besure Build to snag our house. Found quite a few faults I didn't even know were faults. 100% recommend 👌🏻</p>
      `,
      author: "John Shorrock",
      avatarSrc: placeholderAvatar,
    },
    {
      id: 7,
      rating: 5,
      content: `
        <p>Had a snagging list done at our house. Found every single snag in the house, things we would never have noticed. Fantastic report followed in great detail.</p>
      `,
      author: "Tony",
      avatarSrc: placeholderAvatar,
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Reviews = () => {
  return (
    <div className="container bg-white py-5">
      <div className="row">
        <div className="col-lg-4">
          <h2 className="text-dark font-weight-bold">Customer Reviews</h2>

          <div className="mt-3 d-flex align-items-center">
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "10px" }}
            >
              {[0, 1, 2, 3, 4].map((rating) => (
                <FaStar
                  key={rating}
                  className={classNames(
                    reviews.average > rating ? "text-warning" : "text-muted"
                  )}
                />
              ))}
            </div>
            <p className="text-muted mb-0 ml-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/search?q=besurebuild&oq=besurebuild&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGDwyCQgCEAAYDRiABDIJCAMQABgNGIAEMgYIBBBFGDsyDwgFEC4YDRivARjHARiABDIGCAYQRRg8MgYIBxBFGDzSAQgxNzA2ajBqNKgCALACAQ&sourceid=chrome&ie=UTF-8#lrd=0xa2003dc7de66db3:0xbc2d31d6c2991e7e,1,,,,"
                style={{ textDecoration: "none" }}
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              >
                Based on {reviews.totalCount} reviews
              </a>
            </p>
          </div>

          <div className="mt-4">
            {reviews.counts.map((count) => (
              <div key={count.rating} className="d-flex align-items-center">
                <span>{count.rating} stars</span>
                <div
                  className="progress flex-grow-1 mx-2"
                  style={{ height: "1rem" }}
                >
                  <div
                    className="progress-bar bg-warning"
                    role="progressbar"
                    style={{
                      width: `${(count.count / reviews.totalCount) * 100}%`,
                    }}
                    aria-valuenow={count.count}
                    aria-valuemin="0"
                    aria-valuemax={reviews.totalCount}
                  ></div>
                </div>
                <span>
                  {Math.round((count.count / reviews.totalCount) * 100)}%
                </span>
              </div>
            ))}
          </div>

          <div className="mt-4 mb-4">
            <h3 className="h5">Share your thoughts</h3>
            <p>
              If you’ve used our service, share your thoughts with other
              customers.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/search?q=besurebuild&oq=besurebuild&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGDwyCQgCEAAYDRiABDIJCAMQABgNGIAEMgYIBBBFGDsyDwgFEC4YDRivARjHARiABDIGCAYQRRg8MgYIBxBFGDzSAQgxNzA2ajBqNKgCALACAQ&sourceid=chrome&ie=UTF-8#lrd=0xa2003dc7de66db3:0xbc2d31d6c2991e7e,1,,,,"
              className="btn btn-outline-dark"
            >
              Write a review
            </a>
          </div>
        </div>

        <div className="col-lg-8">
          <h3 className="sr-only">Recent reviews</h3>
          <div>
            {reviews.featured.map((review) => (
              <div key={review.id} className="border-bottom py-3">
                <div className="d-flex align-items-center">
                  <img
                    src={review.avatarSrc || placeholderAvatar}
                    alt={`${review.author}`}
                    className="rounded-circle mr-3"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                  <div>
                    <h5 className="mb-0">{review.author}</h5>
                    <div className="d-flex align-items-center mt-1">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <FaStar
                          key={rating}
                          className={classNames(
                            review.rating > rating
                              ? "text-warning"
                              : "text-muted"
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  className="mt-3"
                  dangerouslySetInnerHTML={{ __html: review.content }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
