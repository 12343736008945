import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import ConstructionServicesDetailsHeader from './ConstructionServicesDetailsHeader/ConstructionServicesDetailsHeader';
import ConstructionServicesDetailsArea from './ConstructionServicesDetailsArea/ConstructionServicesDetailsArea';

const ConstructionServices = () => {
  return (
    <>
      <PageHelmet pageTitle='Construction' />
      <HomeOneHeader />
      <ConstructionServicesDetailsHeader />
      <ConstructionServicesDetailsArea />
      <Footer />
    </>
  );
};

export default ConstructionServices;
