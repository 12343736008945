import React from "react";

const ConstructionServicesDetailsArea = () => {
  return (
    <>
      <section className="services__details pt-50 pb-50">
        <div className="container">
          <div className="row">
            {/* Main Content Area */}
            <div className="col-xl-8 col-lg-8">
              <div className="services__text">
                <h2 className="mb-20">Construction Services</h2>
                <p>
                  BeSure Build's service delivers expert building inspections
                  throughout various construction stages to ensure high-quality
                  standards and compliance. From early-stage assessments to
                  final handover, we cover every critical aspect of the build
                  while also offering specialised checks and training for
                  upcoming site managers.
                </p>
              </div>
              <div className="services__list mb-40">
                <ul>
                  <li>
                    Final Handover Inspections: Thorough snagging checks to
                    identify and resolve issues before project completion and
                    handover.
                  </li>
                  <li>
                    Stage Inspections: Regular inspections at key construction
                    stages to catch defects early and maintain quality control.
                  </li>
                  <li>
                    Training for Trainee Managers: Practical, hands-on guidance
                    for trainee managers, helping them learn how to effectively
                    carry out inspections and maintain quality throughout the
                    build process.
                  </li>
                </ul>
              </div>
              <div className="services__img mb-45 w-img">
                <img
                  src="assets/img/services/details/new-build-plot.webp"
                  alt="new build plot"
                />
              </div>
            </div>
            {/* Sidebar Area */}
            <div className="col-xl-4 col-lg-4">
              <div className="faq__accordion p-relative">
                <div className="accordion" id="accordionExample">
                  <div className="card accordion-item">
                    <div className="card-header accordion-header" id="acc_1">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_1"
                          aria-expanded="true"
                          aria-controls="collapse_1"
                        >
                          Why use BeSure Build?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_1"
                      className="collapse show"
                      aria-labelledby="acc_1"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          At BeSureBuild, our Level 6 Site Manager brings over
                          20 years of extensive experience in the construction
                          industry. With a background that spans roles from
                          Finishing Manager to Site Manager, working with both
                          contractors and main contractors, we have successfully
                          overseen all types of projects. We ensure every
                          project is delivered to the highest standards by
                          adhering to all necessary regulations and standards
                          specific to each job. This combination of experience,
                          expertise, and commitment guarantees exceptional
                          results with every build.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_2">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_2"
                          aria-expanded="true"
                          aria-controls="collapse_2"
                        >
                          Pricing
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_2"
                      className="collapse"
                      aria-labelledby="acc_2"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          Quotes for our services are provided on a case-by-case
                          basis, as the cost will depend on the size and
                          complexity of the project & whether you require
                          reoccuring inspections or a one-off inspection. We
                          offer competitive rates for all our services, ensuring
                          that you receive the best value for your investment.
                          To get a quote for your project, please contact us
                          with the details of your requirements, and we will be
                          happy to provide you with a personalised quote.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="acc_4">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_4"
                          aria-expanded="true"
                          aria-controls="collapse_4"
                        >
                          Which areas do you cover?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapse_4"
                      className="collapse"
                      aria-labelledby="acc_4"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="card-body accordion-body">
                        <p>
                          We cover the the North West of England, including
                          Preston, Blackpool, Lancaster, Manchester, Liverpool,
                          and surrounding areas. If you are unsure whether we
                          cover your area, please get in touch with us to
                          discuss your requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConstructionServicesDetailsArea;
