import React from 'react';
import HomeOneHeader from './HomeOneHeader/HomeOneHeader';
import HomeHeroSlider from './HomeHeroArea/HomeHeroSlider';
import HomeAbout from './HomeAbout/HomeAbout';
import {
  HomeCapabilities,
  HomeCapabilitiesReverse
} from './HomeCapabilities/HomeCapabilities';
// import HomeContact from './HomeContact/HomeContact';
import Footer from '../../components/shared/Footer';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';

const Home = () => {
  return (
    <>
      <PageHelmet pageTitle='Home' />
      <HomeOneHeader />
      <HomeHeroSlider />
      <HomeAbout />
      <HomeCapabilities />
      <CommonCtaArea />
      <HomeCapabilitiesReverse />
      {/* <HomeContact /> */}
      <Footer />
    </>
  );
};

export default Home;
